import { useEffect, useMemo, useState } from 'react'
import ScrollTopButton from '@csc/dls/ScrollTopButton'

const SCROLL_FROM_BOTTOM_THRESHOLD = 90
const PAGE_LENGTH_MULTIPLIER = 2

const BackToTop = () => {
  // the thing should not even exist if page fold too short
  const [shouldRender, setShouldRender] = useState(false)
  // the thing should not SHOW if not scrolled enough
  const [shouldDisplay, setShouldDisplay] = useState(false)

  const display = useMemo(() => (shouldRender && shouldDisplay), [shouldDisplay, shouldRender])

  useEffect(() => {
    const scrollListener = () => {
      const { innerHeight, scrollY, document: { documentElement: { offsetHeight } } } = window
      const foldRatio = offsetHeight / innerHeight
      const percentFromBottom = ((offsetHeight - innerHeight - scrollY) / offsetHeight) * 100
      setShouldRender(foldRatio >= PAGE_LENGTH_MULTIPLIER)
      setShouldDisplay(percentFromBottom <= SCROLL_FROM_BOTTOM_THRESHOLD)
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  if (!display) {
    return null
  }

  return (
    <ScrollTopButton data-testid="scroll-to-top-button" />
  )
}

export default BackToTop
